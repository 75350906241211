import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import Img from "gatsby-image"
import "../components/highlightcard.css";


const HighlightCard = ({title, slug, conference, year, thumb}) => {
	return (
		<div className="card border-0 h-100 mb-3 highlight-card rounded" key={slug}>
			{/*<div className="card-header"><h6></h6></div>*/}
			<div className="card-body p-2">
				<div className="card-title  border-bottom py-2 d-flex justify-content-between text-center fs-4">
					<Link to={"/research/" + slug}>

					<span className="d-inline-block">{title}</span>
					</Link>

					{/*<a href="#" className="card-link text-black-50 text-right d-inline-block">*/}
					{/*	<i className="fas fa-file-alt fa-lg fa-fw" aria-hidden="true"></i>*/}
					{/*</a>*/}
				</div>
				{/*<em>{title_long}</em>*/}
				<h6 className="card-subtitle my-2 text-info text-center">{conference} ({year})</h6>
				<Link to={"/research/" + slug}>
				{/*	<img src={image} alt="" className="my-2 card-img-top"/>*/}
				<Img fluid={thumb.childImageSharp.fluid} style={{width: 350}} className="mx-auto align-self-center d-flex" />

				</Link>
				{/*<div className="card-text mt-2 py-2">{text}</div>*/}
				{/*<div className="card-text text-muted">{tags}</div>*/}
			</div>
			{/*<div*/}
			{/*    className="card-footer py-2 bg-secondary d-flex justify-content-between">*/}
			{/*    <div className="px-2">*/}
			{/*        <a href="" className="card-link text-muted">*/}
			{/*            <i className="fas fa-file-alt fa-fw"/>*/}
			{/*        </a>*/}
			{/*    </div>*/}
			{/*    <div className="px-2">*/}
			{/*        <a href="" className="card-link text-muted">*/}
			{/*            <i className="fas fa-quote-right fa-fw"/>*/}
			{/*        </a>*/}
			{/*    </div>*/}
			{/*    <div className="px-2">*/}
			{/*        <a href="" className="card-link text-muted">*/}
			{/*            <i className="fas fa-play fa-fw"/>*/}
			{/*        </a>*/}
			{/*    </div>*/}
			{/*</div>*/}
		</div>
	);
};

export default HighlightCard;